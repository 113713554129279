const ready = require('./utilities').ready;
let container;
let loadMoreButton;

ready(init);

function init() {
  container = document.querySelector('.js-load-more-btn');

  if (!container) {
    return;
  }

  createLoadMoreButton();
  checkSignUpBanner();
}

function createLoadMoreButton() {
  loadMoreButton = document.createElement('button');
  loadMoreButton.className = 'load-more-btn button button--default button--big';
  loadMoreButton.textContent = '+ Load More';
  loadMoreButton.addEventListener('click', loadNextPage);
  
  if (hasNextPage()) {
    container.insertAdjacentElement('afterend', loadMoreButton);
  }
}

function hasNextPage() {
  const nextPage = container.getAttribute('data-next-page');
  return nextPage && nextPage !== 'null';
}

function shouldShowSignUpBanner() {
  const currentPage = parseInt(container.getAttribute('data-next-page')) - 1;
  const hasBasicBanner = !!document.querySelector('.sign-up-banner') && !document.querySelector('.sign-up-banner--subscription');
  const hasSubscriptionBanner = !!document.querySelector('.sign-up-banner--subscription');

  return (currentPage >= 1 && hasBasicBanner) || (currentPage >= 2 && hasSubscriptionBanner);
}

function checkSignUpBanner() {
  if (shouldShowSignUpBanner()) {
    showSignUpBanner();
    if (loadMoreButton) {
      loadMoreButton.remove();
    }
  }
}

async function loadNextPage() {
  loadMoreButton.disabled = true;
  loadMoreButton.textContent = 'Loading...';

  if (shouldShowSignUpBanner()) {
    showSignUpBanner();
    loadMoreButton.remove();
  } else {
    const response = await fetch(getUrl());
    const json = await response.json();
    container.setAttribute('data-next-page', json.nextPage);
    container.insertAdjacentHTML('beforeend', json.html);
    
    loadMoreButton.disabled = false;
    loadMoreButton.textContent = '+ Load More';
    
    if (!hasNextPage()) {
      loadMoreButton.remove();
    }
  }
}

function getUrl() {
  let url = new URL(container.getAttribute('data-path'), window.location);
  url.search = new URLSearchParams(url.search.slice(1));
  url.searchParams.append('page', container.getAttribute('data-next-page'));
  url.searchParams.append('format', 'json');
  return url;
}

function showSignUpBanner() {
  document.body.classList.add('show-sign-up-banner');

  gtag('event', 'sign-up-banner', {
    'event_category': 'open',
    'non_interaction': true
  });
}
